import React from "react";
import FaqSection from "../components/elements/FaqSection";

export default function FaqPage() {
    const faqData = [
        {
            question: "Quels services proposez-vous chez NoxziDev ?",
            answer: "Chez NoxziDev, je propose une gamme complète de services de développement web, y compris la création de sites vitrines, e-commerce, d'applications web, la maintenance, l'optimisation SEO et la performance des sites. Je suis spécialisé dans les solutions web personnalisées pour répondre aux besoins uniques de chaque client."
        },
        {
            question: "Comment se déroule le processus de développement d’un site web ?",
            answer: ""
        },
        {
            question: "Combien de temps faut-il pour créer un site web ?",
            answer: "La durée dépend de la complexité du projet. Un site vitrine simple peut prendre 2 à 4 semaines, tandis qu’un projet plus complexe comme une plateforme sur mesure peut nécessiter plusieurs mois. Je te fournirai un calendrier détaillé après avoir évalué ton projet."
        },
        {
            question: "Est-ce que NoxziDev propose la maintenance après la mise en ligne ?",
            answer: "Oui ! Je propose des services de maintenance pour m'assurer que ton site est toujours à jour, sécurisé et fonctionne correctement. Cela inclut la gestion des mises à jour techniques, des corrections de bugs et des optimisations continues."
        },
        {
            question: "Proposez-vous des solutions SEO pour améliorer la visibilité de mon site ?",
            answer: "Oui, chaque site que je développe est optimisé pour le SEO afin de garantir une bonne visibilité sur les moteurs de recherche. Cela inclut l’optimisation des balises, des images, de la vitesse de chargement, et des pratiques SEO actuelles."
        },
        {
            question: "Combien coûte la création d’un site web chez NoxziDev ?",
            answer: "Le prix varie selon la complexité et les fonctionnalités requises. Après avoir évalué ton projet, je te fournirai un devis personnalisé. Je m’engage à offrir un bon rapport qualité-prix tout en respectant votre budget."
        }
    ]
    return(
        <div>
            <FaqSection faqData={faqData} />
        </div>
    )
}
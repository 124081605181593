import React from "react";


const PolitiqueConfidentialite = () => {
  return (
    <div>
        <div className="btext-black py-10 px-5">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-3xl font-bold mb-5">Politique de Confidentialité</h1>
            <p className="mb-4">
              <strong>Données collectées :</strong> Nous collectons les données personnelles
              suivantes : [liste des données collectées, par exemple : nom, email, téléphone,
              etc.].
            </p>
            <p className="mb-4">
              <strong>Utilisation des données :</strong> Vos données sont utilisées pour :{" "}
              [détail des usages, par exemple : améliorer nos services, envoyer des
              newsletters, etc.].
            </p>
            <p className="mb-4">
              <strong>Conservation des données :</strong> Les données sont conservées pendant
              une durée de [période de conservation].
            </p>
            <p className="mb-4">
              <strong>Vos droits :</strong> Conformément au RGPD, vous avez un droit d'accès,
              de rectification, de suppression et de portabilité de vos données. Pour
              exercer vos droits, contactez-nous à :{" "}
              <a href="mailto:votre-email@example.com" className="text-green-500 underline">
                votre-email@example.com
              </a>
            </p>
          </div>
        </div>
    </div>
  );
};

export default PolitiqueConfidentialite;

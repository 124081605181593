import React from "react";
import Slider from "react-slick";

const CustomPrevArrow = ({ className, style, onClick }) => (
  <button
    className={`${className} absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-green-500 hover:bg-green-700 text-white p-3 rounded-full shadow-md`}
    style={{ ...style }}
    onClick={onClick}
  >
    ❮
  </button>
);

const CustomNextArrow = ({ className, style, onClick }) => (
  <button
    className={`${className} absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-green-500 hover:bg-green-700 text-white p-3 rounded-full shadow-md`}
    style={{ ...style }}
    onClick={onClick}
  >
    ❯
  </button>
);

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const testimonials = [
    {
      id: 1,
      title: "Kornix Is The Best Digital Agency I Have Ever Seen! Highly Recommended!",
      content:
        "I recently hired Ideapeel for a custom web development project and couldn't be happier with the results. The team was able to bring my unique ideas to life and create a website that truly stands out.",
      name: "Noé Bolzer",
      position: "Directeur d’HYDRO-EVENTS",
      image: "/path/to/image.jpg", // Remplacez par le chemin de l'image
    },
    {
      id: 2,
      title: "Their expertise is unmatched, and their dedication to quality is commendable.",
      content:
        "Choosing Kornix was the best decision we made for our business. Their team truly understands customer needs and delivers beyond expectations!",
      name: "Jane Doe",
      position: "CEO, Startup Inc.",
      image: "/path/to/image2.jpg", // Remplacez par le chemin de l'image
    },
  ];

  return (
    <section className="mb-36 py-12 px-4 sm:px-8 md:px-12 lg:px-16">
      <div className="container mx-auto text-center">
        <div className="text-center">
          <h2 className="text-3xl sm:text-4xl font-bold text-[#191919] mb-4">
            Nos clients <br />
            <span className="text-[#3AA600]">Parlent de nous</span>
          </h2>
          <p className="text-[#00000099] mb-12 text-sm sm:text-base md:text-lg">
            Lorem ipsum dolor sit amet consectetur. Amet sed quis habitant faucibus.
          </p>
        </div>
        <div className="max-w-4xl mx-auto">
          <Slider {...settings}>
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.id}
                className="bg-[#F4F6F3] border border-[#00000012] p-6 sm:p-8 rounded-lg shadow-md"
              >
                <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-4">
                  {testimonial.title}
                </h3>
                <p className="text-gray-700 mb-6 text-sm sm:text-base">
                  {testimonial.content}
                </p>
                <div className="flex items-center gap-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-12 h-12 sm:w-16 sm:h-16 rounded-full object-cover"
                  />
                  <div>
                    <p className="text-green-500 font-bold text-sm sm:text-base">
                      {testimonial.name}
                    </p>
                    <p className="text-gray-500 text-xs sm:text-sm">
                      {testimonial.position}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
import React from "react";
import hydroevents from '../../images/portfolio/HYDRO-EVENTS.png'
import noxzidev from '../../images/portfolio/NOXZI-DEV-FIVEM.png'
import dailybrew from '../../images/portfolio/DAILYBREW.png'

const PortfolioSection = () => {
  return (
    <div className="bg-white py-16 mb-20 px-6 max-w-6xl mx-auto md:px-16">
      <div className="text-center">
        <h2 className="text-4xl  text-[#191919] mb-4 ">
          Nos projets<br /><span className="text-[#3AA600]">Vos projets</span> 
        </h2>
        <p className="text-[#00000099] mb-12">
          Lorem ipsum dolor sit amet consectetur. Amet sed quis habitant faucibus.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-0 place-items-center">
        {/* Card 1 */}
        <div className="bg-[#F4F6F3] border border-[#00000012] p-2 h-[478px] w-80 rounded-lg shadow-[inset_-5px_4px_28.4px_0px_#FFFFFF] text-center flex flex-col justify-center">
        <div className="mt-8">
            <img
              src={dailybrew}
              alt="Website preview"
              className="rounded-lg mx-auto my-auto mt-12 inset-0"
            />
          </div>
          <div className="p-4 mt-auto text-left">
            <h3 className="text-base font-semibold text-black uppercase">Dailybrew</h3>
            <h6 className="text-[#3DA808] text-xs">CREATION ET CONCEPTION</h6>
            <p className="text-[#00000099] text-sm">
              Lorem ipsum dolor sit ametdddd consectedddtur. Amet sed quis. 
            </p>
            <div className="mt-2">
            <button className="text-[#000000bf] font-bold text-xs underline rounded-md hover:transition">
                <a href="https://dailybrew.noxzi-dev.fr" target="_blank">Voir le projet</a>
              </button>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="bg-[#F4F6F3] border border-[#00000012] p-2 h-[478px] w-80 rounded-lg text-center relative flex flex-col justify-center shadow-[inset_-5px_4px_28.4px_0px_#FFFFFF]">
          <div className="mt-8">
          <img
              src={hydroevents}
              alt="Website preview"
              className="rounded-lg mx-auto my-auto mt-10 inset-0"
            />
          </div>
          <div className="p-4 mt-auto text-left">
            <h3 className="text-base font-semibold text-black uppercase">HYDRO-EVENTS</h3>
            <h6 className="text-[#3DA808] text-xs">CREATION ET CONCEPTION</h6>
            <p className="text-[#00000099] text-sm">
              Lorem ipsum dolor sit amet consectetur. Amet sed quis. 
            </p>
            <div className="mt-2">
            <button className="text-[#000000bf] font-bold text-xs underline rounded-md hover:transition">
                <a href="https://hydro-events.noxzi-dev.fr" target="_blank">Voir le projet</a>
              </button>
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div className="bg-[#F4F6F3] border border-[#00000012] p-2 h-[478px] w-80 rounded-lg shadow-[inset_-5px_4px_28.4px_0px_#FFFFFF] text-center flex flex-col justify-center">
        <div className="mt-8">
            <img
              src={noxzidev}
              alt="Website preview"
              className="rounded-lg mx-auto  my-auto mt-12 inset-0"
            />
          </div>
          <div className="p-4 mt-auto text-left">
            <h3 className="text-base font-semibold text-black uppercase">noxzi-dev fivem</h3>
            <h6 className="text-[#3DA808] text-xs">CREATION ET CONCEPTION</h6>
            <p className="text-[#00000099] text-sm">
              Lorem ipsum dolor sit amet consectetur. Amet sed quis. 
            </p>
            <div className="mt-2">
            <button className="text-[#000000bf] font-bold text-xs underline rounded-md hover:transition">
                <a href="https://fivem.noxzi-dev.fr" target="_blank">Voir le projet</a>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[1200px] w-[416px] mx-auto h-[309px] blur-348 bg-[#54B03766] my-auto z-0"></div>
      <div className="text-center mt-12">
            <button className="bg-[#D0EAC4] text-[#3AA600] font-bold px-5 py-3 mr-4 rounded-md hover:transition">
                Découvrir notre portfolio
            </button>
      </div>
    </div>
  );
};

export default PortfolioSection;

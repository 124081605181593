import React from "react";
import figmaLogo from '../../images/header/Figma.svg';
import wordpressLogo from '../../images/header/wordpress.svg';
import shopifyLogo from '../../images/header/Shopify.svg';
import reactLogo from '../../images/header/React.svg';
import cssLogo from '../../images/header/css.svg';
import tailwindcssLogo from '../../images/header/Tailwindcss.svg';
import jsLogo from '../../images/header/js.svg';
import htmlLogo from '../../images/header/html5.svg';
import grid from '../../images/header/grid-header.png';

const Header = () => {
  return (
    <header className="mb-16 flex flex-col items-center  text-center px-6">
      {/* Badge */}
      <div className="mt-12 bg-gradient-to-t from-[#E9ECEF] to-[#D0E9C4] text-[#6C757D] border-2 border-[#CCD9CC78] font-semibold py-2 px-6 rounded-full text-sm z-10">
        L’agence Web N°1 en Savoie
      </div>

      <img
        src={grid}
        alt="Grid background"
        className="absolute top-32 w-full h-[708px] object-cover z-0"
      />
      {/* Title */}
      <h1 className="text-4xl mt-4 text-[#393939] md:text-5xl font-extrabold mb-6 z-10" style={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
        <span className="text-[#191919]">Propulsez votre Présence Digitale avec</span> <br /> des Solutions Web Innovantes.
      </h1>

      <div className="absolute top-56 w-[416px] mx-auto h-[309px] blur-348 bg-[#54B03766] mx-auto my-auto z-0"></div>
      {/* Subtitle */}
      <p className="text-[#00000099] text-base md:text-xl max-w-4xl mb-8 z-10">
     
        Bienvenue chez <span className="font-bold">NoxZi-Dev</span>, une agence de développement web à taille humaine, dirigée par un développeur passionné et expérimenté. Avec une expertise complète dans le développement de sites web modernes, d'applications sur mesure et de solutions digitales performantes, NoxZi-Dev s'engage à propulser votre projet vers le succès.
      </p>

      {/* Buttons */}
      <div className="flex space-x-4 z-10">
            <button className="bg-[#D0EAC4] text-[#3AA600] font-bold px-5 py-3 mr-4 rounded-md hover:transition">
                Obtenir un devis
            </button>
            <button className="text-black font-bold px-5 py-3 rounded-md hover:transition">
                En savoir plus
            </button>
      </div>

      {/* Technologies */}
      <div className="mt-20">
    <h2 className="text-[#00000066] text-sm sm:text-base mb-4 text-center">
        Technologies utilisées :
    </h2>
    <div className="flex flex-wrap justify-center gap-4 sm:gap-6">
        <img src={figmaLogo} alt="Figma logo" className="h-8 sm:h-9 md:h-10" />
        <img src={wordpressLogo} alt="Wordpress logo" className="h-8 sm:h-9 md:h-10" />
        <img src={shopifyLogo} alt="Shopify logo" className="h-8 sm:h-9 md:h-10" />
        <img src={reactLogo} alt="React logo" className="h-8 sm:h-9 md:h-10" />
        <img src={cssLogo} alt="CSS3 logo" className="h-8 sm:h-9 md:h-10" />
        <img src={tailwindcssLogo} alt="Tailwindcss logo" className="h-8 sm:h-9 md:h-10" />
        <img src={jsLogo} alt="JavaScript logo" className="h-8 sm:h-9 md:h-10" />
        <img src={htmlLogo} alt="HTML5 logo" className="h-8 sm:h-9 md:h-10" />
    </div>
</div>
    </header>
  );
};

export default Header;

import React, { useState } from "react";
import arrowsDown from "../../images/arrow.png";
import arrowsUp from "../../images/arrow2.png";

export default function FaqSection({ faqData }) {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAnswer = (index) => {
        if (index === activeIndex) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <section className="bg-white py-16 px-4 sm:px-8">
            <div className="text-center mb-12">
                <h2 className="text-black text-3xl sm:text-5xl font-bold">FAQ</h2>
                <p className="text-gray-600 text-sm sm:text-base">
                    Providing answers to your questions
                </p>
            </div>

            <div className="max-w-4xl mx-auto">
                {faqData.map((faq, index) => (
                    <div
                        key={index}
                        className="bg-[#F4F6F3] border border-[#00000012] mx-auto w-full sm:w-[655px] p-4 sm:p-6 rounded-2xl mb-4 sm:mb-8 cursor-pointer"
                        onClick={() => toggleAnswer(index)}
                    >
                        <div className="flex justify-between items-center">
                            <h2 className="text-lg sm:text-xl font-medium text-gray-800">
                                {faq.question}
                            </h2>
                            <div className="rounded-full w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center">
                                {activeIndex === index ? (
                                    <img
                                        className="w-6 h-6 sm:w-10 sm:h-10"
                                        src={arrowsUp}
                                        alt="Arrow up"
                                    />
                                ) : (
                                    <img
                                        className="w-6 h-6 sm:w-10 sm:h-10"
                                        src={arrowsDown}
                                        alt="Arrow down"
                                    />
                                )}
                            </div>
                        </div>
                        {activeIndex === index && (
                            <p className="text-sm sm:text-base text-gray-700 mx-2 sm:mx-4 mt-2 sm:mt-4">
                                {faq.answer}
                            </p>
                        )}
                    </div>
                ))}
            </div>
        </section>
    );
}
import React, { useState } from "react";
import emailjs from "emailjs-com";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_at61mni", // Replace with your EmailJS Service ID
        "template_69ad9td", // Replace with your EmailJS Template ID
        formData,
        "YbGXUnW8SGN27r_9a" // Replace with your EmailJS User ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setStatus("Votre message a été envoyé avec succès !");
          setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
          });
        },
        (err) => {
          console.error("FAILED...", err);
          setStatus("Une erreur s'est produite. Veuillez réessayer.");
        }
      );
  };

  return (
    <section className="flex flex-col items-center text-center px-6 py-12 ">
      {/* Badge */}
      <div className="bg-gradient-to-t from-[#E9ECEF] to-[#D0E9C4] text-[#6C757D] border border-[#CCD9CC78] text-sm font-semibold px-4 py-1 rounded-full mb-4">
        Prise de contact
      </div>

      {/* Title */}
      <h2 className="text-3xl md:text-4xl font-bold text-[#000000CC] mb-2">
        Contactez-nous
      </h2>
      <p className="text-[#00000099] text-sm md:text-base mb-8">
        Lorem ipsum dolor sit amet consectetur. Amet sed quis habitant faucibus.
      </p>

      {/* Form */}
      <form
        className="w-full max-w-4xl mx-auto space-y-6"
        onSubmit={handleSubmit}
      >
        {/* Name Input */}
        <div>
          <input
            type="text"
            name="name"
            placeholder="Votre Nom"
            value={formData.name}
            onChange={handleChange}
            required
            className="w-full px-4 py-3 bg-[#F6FAF6] border border-[#CCD9CC] rounded-lg shadow-md text-sm text-[#C9C9C9] placeholder-[#00000099]"
          />
        </div>

        {/* Email Input */}
        <div>
          <input
            type="email"
            name="email"
            placeholder="Votre Email"
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full px-4 py-3 bg-[#F6FAF6] border border-[#CCD9CC] rounded-lg shadow-md text-sm text-[#C9C9C9] placeholder-[#00000099]"
          />
        </div>

        {/* Phone Number Input */}
        <div>
          <input
            type="tel"
            name="phone"
            placeholder="Votre Numéro de téléphone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full px-4 py-3 bg-[#F6FAF6] border border-[#CCD9CC] rounded-lg shadow-md text-sm text-[#C9C9C9] placeholder-[#00000099]"
          />
        </div>

        {/* Message Input */}
        <div>
          <textarea
            name="message"
            rows="4"
            placeholder="Votre Message"
            value={formData.message}
            onChange={handleChange}
            required
            className="w-full px-4 py-3 bg-[#F6FAF6] border border-[#CCD9CC] h-60 rounded-lg shadow-md text-sm text-[#C9C9C9] placeholder-[#00000099]"
          ></textarea>
        </div>

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            className="w-full bg-[#D0EAC4] text-[#3AA600] font-bold py-3 rounded-md hover:bg-[#c3e2b9] transition"
          >
            Envoyer
          </button>
        </div>
      </form>

      {/* Status Message */}
      {status && (
        <p className="mt-4 text-sm font-semibold text-[#3AA600]">{status}</p>
      )}
    </section>
  );
};

export default ContactSection;
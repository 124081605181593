import React from "react";

const SectionConfiance = () => {
  return (
    <section className="min-h-screen flex flex-col items-center text-center px-4 sm:px-6 lg:px-8 py-12">
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#000000CC] mb-4">
        Nous choisir, <span className="text-[#3AA600]">Choisir la Confiance</span>
      </h2>
      <p className="text-[#00000099] text-sm md:text-base mb-8">
        Lorem ipsum dolor sit amet consectetur. Amet sed quis habitant faucibus.
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl">
        <div className="bg-[#F4F6F3] border border-[#00000012] h-auto rounded-lg p-4 shadow-md flex flex-col">
          <div className="mt-4">
            <img
              src="https://via.placeholder.com/150"
              alt="Website preview"
              className="rounded-lg mx-auto"
            />
          </div>
          <div className="p-4 mt-auto text-left">
            <h3 className="text-base font-semibold text-black">Un service personnalisé</h3>
            <p className="text-[#00000099] text-sm">
              Chaque projet est unique. Je prends le temps de comprendre vos objectifs pour créer une solution qui répond parfaitement à vos besoins.
            </p>
          </div>
        </div>

        <div className="bg-[#F4F6F3] border border-[#00000012] h-auto rounded-lg p-4 shadow-md flex flex-col">
          <div className="mt-4">
            <img
              src="https://via.placeholder.com/150"
              alt="Website preview"
              className="rounded-lg mx-auto"
            />
          </div>
          <div className="p-4 mt-auto text-left">
            <h3 className="text-base font-semibold text-black">Transparence et communication</h3>
            <p className="text-[#00000099] text-sm">
              Vous serez toujours informé de l'avancée du projet et de chaque étape du processus de développement.
            </p>
          </div>
        </div>

        <div className="bg-[#F4F6F3] border border-[#00000012] h-auto rounded-lg p-4 shadow-md flex flex-col lg:row-span-2">
          <div className="mt-4">
            <img
              src="https://via.placeholder.com/150"
              alt="Website preview"
              className="rounded-lg mx-auto"
            />
          </div>
          <div className="p-4 mt-auto text-left">
            <h3 className="text-base font-semibold text-black">Design UI/UX</h3>
            <p className="text-[#00000099] text-sm">
              Lorem ipsum dolor sit amet consectetur. Amet sed quis.
            </p>
          </div>
        </div>

        <div className="bg-[#F4F6F3] border border-[#00000012] h-auto rounded-lg p-4 shadow-md flex flex-col sm:col-span-2 lg:col-span-2">
          <div className="mt-4">
            <img
              src="https://via.placeholder.com/150"
              alt="Website preview"
              className="rounded-lg mx-auto"
            />
          </div>
          <div className="p-4 mt-auto text-left">
            <h3 className="text-base font-semibold text-black">Passion et innovation</h3>
            <p className="text-[#00000099] text-sm">
              En tant que développeur indépendant, je suis toujours à la recherche des dernières innovations pour offrir des solutions avant-gardistes.
            </p>
          </div>
        </div>
      </div>

      {/* Button */}
      <div className="mt-8">
        <button className="bg-[#D0EAC4] text-[#3AA600] font-bold px-6 py-3 rounded-md hover:bg-[#c3e2b9] transition">
          Découvrir nos services
        </button>
      </div>
    </section>
  );
};

export default SectionConfiance;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoNoxzi from "../../images/logoNoxzi.png";
import xLogo from "../../images/x.svg";
import linkedinLogo from "../../images/linkedin.svg";
import youtubeLogo from "../../images/youtube.svg";
import instagramLogo from "../../images/instagram.svg";
import rdv from "../../images/rdv.svg";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <nav className="mx-auto mt-8 border-[#DFDFDF] max-w-screen-xl px-4 md:mx-96 sm:px-6 lg:px-8">
      <div className="bg-white shadow-lg px-6 border-[#DFDFDF] border py-4 flex justify-between items-center rounded-lg">
        {/* Logo */}
        <div className="flex items-center space-x-2">
          <img src={logoNoxzi} alt="Logo" className="h-10" />
        </div>

        {/* Desktop Navigation Links */}
        <ul className="hidden md:flex space-x-8 text-gray-800 text-sm font-semibold">
          <li>
            <Link to="/" className="hover:text-green-500">
              Accueil
            </Link>
          </li>
          <li>
            <Link to="/services" className="hover:text-green-500">
              Services
            </Link>
          </li>
          <li>
            <Link to="/contact" className="hover:text-green-500">
              Contact
            </Link>
          </li>
          <li>
            <Link to="/faq" className="hover:text-green-500">
              F.A.Q
            </Link>
          </li>
          <li>
            <a href="https://fivem.noxzi-dev.fr" className="hover:text-green-500">
              Fivem
            </a>
          </li>
        </ul>

        {/* Devis Button */}
        <button className="hidden md:block bg-[#D0EAC4] text-[#3AA600] font-bold px-5 py-2 rounded-md hover:transition">
          Obtenir un devis
        </button>

        {/* Mobile Menu Toggle */}
        <button
          className="md:hidden text-gray-800 focus:outline-none"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          {isMobileMenuOpen ? (
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          )}
        </button>
      </div>

      {/* Mobile Navigation Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-white border-[#DFDFDF] border rounded-lg shadow-lg mt-2 px-6 py-4">
          <ul className="space-y-4 text-gray-800 text-sm font-semibold">
            <li>
              <Link to="/" className="block hover:text-green-500">
                Accueil
              </Link>
            </li>
            <li>
              <Link to="/services" className="block hover:text-green-500">
                Services
              </Link>
            </li>
            <li>
              <Link to="/contact" className="block hover:text-green-500">
                Contact
              </Link>
            </li>
            <li>
              <Link to="/faq" className="block hover:text-green-500">
                F.A.Q
              </Link>
            </li>
            <li>
              <a href="https://fivem.noxzi-dev.fr" className="block hover:text-green-500">
                Fivem
              </a>
            </li>
          </ul>
          <button className="mt-4 flex items-center text-[#737D6F] text-sm hover:underline">
            <img src={rdv} alt="Rdv logo" className="mr-2" />
            <span>Planifiez un rendez-vous</span>
          </button>
          <button className="block w-full bg-[#D0EAC4] text-[#3AA600] font-bold px-5 py-2 rounded-md mt-4">
            Obtenir un devis
          </button>
        </div>
      )}

      {/* Bottom Section */}
      <div className="flex justify-between items-center my-2">
        <div>
          <button className="hidden md:flex items-center text-[#737D6F] text-sm hover:underline">
            <img src={rdv} alt="Rdv logo" className="mr-2" />
            <span>Planifiez un rendez-vous</span>
          </button>
        </div>

        <div className="flex space-x-4 text-[#737D6F] text-sm">
          <p>Suivez-nous </p>
          <a href="#" className="text-gray-500 hover:text-gray-700">
            <img src={xLogo} alt="X logo" className="h-4" />
          </a>
          <a href="#" className="text-gray-500 hover:text-gray-700">
            <img src={linkedinLogo} alt="Linkedin logo" className="h-4" />
          </a>
          <a href="https://www.youtube.com/@noxzi-dev" className="text-gray-500 hover:text-gray-700">
            <img src={youtubeLogo} alt="Youtube logo" className="h-4" />
          </a>
          <a href="https://www.instagram.com/noxzidev" className="text-gray-500 hover:text-gray-700">
            <img src={instagramLogo} alt="Instagram Logo" />
          </a>
          <a href="#" className="text-gray-500 hover:text-gray-700">
            <i className="fab fa-behance"></i>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React from "react";


export default function MentionsLegales() {
    return(
        <div>
       
        <div className="text-black py-10 px-5">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold mb-5">Mentions Légales</h1>
          <p className="mb-4">
            <strong>Éditeur du site :</strong> <br />
            Nom : NoxZi-Dev <br />
            Adresse : 792 Chemin de Charrière Neuve, 73000 Chambéry, France <br />
            SIRET : 981 685 662 000 18 <br />
            Code APE: 4791A <br />
            RCS: Chambery A 981 685 662 <br />
            Responsable de publication : Faure Lionel <br/>
            Email : <a href="mailto:contact@noxzi-dev.fr">contact@noxzi-dev.fr</a>
          </p>
          <p className="mb-4">
            <strong>Hébergeur :</strong> <br />
            Nom : LWS (Ligne Web Services) <br />
            Adresse : Adresse: 10 Rue Penthièvre, 75008 Paris, France <br />
            Téléphone : +33 1 77 62 30 03
          </p>
          <p>
            Pour toute question, contactez-nous à :{" "}
            <a
              href="mailto:contact@noxzi-dev.fr"
              className="text-green-500 underline"
            >
              contact@noxzi-dev.fr
            </a>
          </p>
        </div>
      </div>
      </div>
    )
}
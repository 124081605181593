import React from "react";


const CGU = () => {
  return (
    <div>

    <div className="text-black py-10 px-5">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-5">Conditions Générales d’Utilisation</h1>
        <p className="mb-4">
          <strong>1. Objet :</strong> Ces conditions générales définissent les règles
          d’utilisation du site NoxZi-Dev.
        </p>
        <p className="mb-4">
          <strong>2. Responsabilités :</strong> L’éditeur n’est pas responsable des
          éventuelles interruptions ou erreurs rencontrées sur le site. L’utilisateur est
          seul responsable de l’utilisation des informations disponibles.
        </p>
        <p className="mb-4">
          <strong>3. Propriété intellectuelle :</strong> Tous les contenus (textes,
          images, vidéos) présents sur ce site sont protégés par le droit d’auteur.
          Toute reproduction est interdite sans autorisation préalable.
        </p>
        <p className="mb-4">
          <strong>4. Modifications :</strong> Nous nous réservons le droit de modifier
          ces conditions à tout moment.
        </p>
      </div>
    </div>
    </div>
  );
};

export default CGU;

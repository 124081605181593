import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from './pages/Home';
import ServicesPage from './pages/Services';
import PortfolioPage from './pages/Portfolio';
import FaqPage from './pages/Faq';
import ContactPage from './pages/Contact';

import MentionsLegales from './pages/legal/mentionsLegals';
import CookieBanner from './components/layout/CookieBanner';
import PolitiqueConfidentialite from './pages/legal/PolitiqueConfidentialite';
import CGU from './pages/legal/CGU';
import PolitiqueCookies from './pages/legal/PolitiqueCookies';
import Navbar from './components/layout/NavBar';
import Footer from './components/layout/Footer';

const App = () => {
  return (
    <Router>
      <div className="bg-[#FFF]">
        <Navbar />
        <CookieBanner />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/services' element={<ServicesPage />} />
          <Route path='/portfolio' element={<PortfolioPage />} />
          <Route path='/faq' element={<FaqPage />} />
          <Route path='/contact' element={<ContactPage />} />

          <Route path="/mentions-legales" element={<MentionsLegales />} />
          <Route path="/politique-confidentialite" element={<PolitiqueConfidentialite />} />
          <Route path="/cgu" element={<CGU />} />
          <Route path="/politique-cookies" element={<PolitiqueCookies />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;

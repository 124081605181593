import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accepter"
      declineButtonText="Refuser"
      enableDeclineButton
      style={{
        background: "#000",
        width: "400px",
        height: "200px",
        position: "fixed",
        bottom: "20px",
        margin: "40px",
        right: "20px",
        borderRadius: "10px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      buttonStyle={{
        color: "#000",
        fontSize: "13px",
        backgroundColor: "#77ff4a",
        borderRadius: "6px",
        padding: "8px 12px",
        marginTop: "10px",
      }}
      declineButtonStyle={{
        color: "#fff",
        backgroundColor: "#ff5c5c",
        fontSize: "13px",
        borderRadius: "6px",
        padding: "8px 12px",
        marginBottom: "10px",
      }}
      expires={150}
    >
      <h1 style={{ marginBottom: "15px", fontSize:"24px", fontWeight: "800"}}>🍪 Cookies</h1>
      <p style={{ color: "#fff", fontSize: "14px", marginBottom: "10px" }}>
        Ce site utilise des cookies pour améliorer votre expérience.{" "}
        <a href="/politique-cookies" style={{ color: "#B9FD50", textDecoration: "underline" }}>
          En savoir plus.
        </a>
      </p>
    </CookieConsent>
  );
};

export default CookieBanner;

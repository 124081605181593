import React from "react";
import websiteCreate from '../../images/website.png'

const ServicesSectionHome = () => {
  return (
    <div className="bg-white py-16 mb-20 px-6 max-w-7xl mx-auto md:px-16">
      <div className="text-center">
        <h2 className="text-4xl  text-[#191919] mb-4 ">
          Quels sont les services de <br /><span className="text-[#3AA600]">NoxZi-Dev ?</span> 
        </h2>
        <p className="text-[#00000099] mb-12">
          Lorem ipsum dolor sit amet consectetur. Amet sed quis habitant faucibus.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 place-items-center">
        {/* Card 1 */}
        <div className="bg-[#F4F6F3] border border-[#00000012] p-2 h-96 w-80 rounded-lg shadow-[inset_-5px_4px_28.4px_0px_#FFFFFF] text-center flex flex-col justify-center">
        <div className="mt-8">
            <img
              src="https://via.placeholder.com/150"
              alt="Website preview"
              className="rounded-lg mx-auto my-auto inset-0"
            />
          </div>
          <div className="p-4 mt-auto text-left">
            <h3 className="text-base font-semibold text-black">Création de Site Internet</h3>
            <p className="text-[#00000099] text-sm">
              Lorem ipsum dolor sit ametdddd consectedddtur. Amet sed quis. 
            </p>
          </div>
        </div>

        {/* Card 2 */}
        <div className="bg-[#F4F6F3] border border-[#00000012] p-2 h-[478px] w-96 rounded-lg text-center relative flex flex-col justify-center shadow-[inset_-5px_4px_28.4px_0px_#FFFFFF]">
          <div className="absolute top-4 right-0 bg-gradient-to-t from-[#E9ECEF] to-[#D0E9C4] text-[#6C757D] border border-[#CCD9CC78]  text-xs font-semibold px-4 py-1 rounded-l-full">
            Notre offre la plus populaire
          </div>
          <div className="mt-8">
            <img
              src={websiteCreate}
              alt="Website preview"
              className="rounded-lg mx-auto mt-12 h-72 my-auto inset-0"
            />
          </div>
          <div className="p-4 mt-auto text-left">
            <h3 className="text-base font-semibold text-black">Création de Site Internet</h3>
            <p className="text-[#00000099] text-sm">
              Lorem ipsum dolor sit amet consectetur. Amet sed quis. 
            </p>
          </div>
        </div>

        {/* Card 3 */}
        <div className="bg-[#F4F6F3] border border-[#00000012] p-2 h-96 w-80 rounded-lg shadow-[inset_-5px_4px_28.4px_0px_#FFFFFF] text-center flex flex-col justify-center">
        <div className="mt-8">
            <img
              src="https://via.placeholder.com/150"
              alt="Website preview"
              className="rounded-lg mx-auto  my-auto inset-0"
            />
          </div>
          <div className="p-4 mt-auto text-left">
            <h3 className="text-base font-semibold text-black">Design UI/UX</h3>
            <p className="text-[#00000099] text-sm">
              Lorem ipsum dolor sit amet consectetur. Amet sed quis. 
            </p>
          </div>
        </div>
      </div>
      <div className="absolute top-[1200px] w-[416px] mx-auto h-[309px] blur-348 bg-[#54B03766] my-auto z-0"></div>
      <div className="text-center mt-12">
            <button className="bg-[#D0EAC4] text-[#3AA600] font-bold px-5 py-3 mr-4 rounded-md hover:transition">
                Découvrir nos services
            </button>
      </div>
    </div>
  );
};

export default ServicesSectionHome;

import React from "react";
import { Link } from "react-router-dom";
import xLogo from "../../images/twitter_nav.svg";
import linkedinLogo from "../../images/linkedin_nav.svg";
import youtubeLogo from "../../images/youtube_nav.svg";
import instagramLogo from "../../images/instagram_nav.svg";
import logo from "../../images/logoNoxzi.png";

export default function Footer() {
    return (
        <footer className="bg-white text-black py-10 px-4 sm:px-10 md:px-20 lg:px-32">
            <div className="container grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-20">
                {/* Section gauche */}
                <div>
                    <div className="flex items-center mb-6">
                        <img className="h-12 mr-4" src={logo} alt="NoxZi Logo" />
                        <h2 className="text-xl md:text-3xl font-bold text-black">NoxZi-Dev</h2>
                    </div>
                    <div className="text-[#878484] mb-6">
                        <p>
                            Email:{" "}
                            <a
                                href="mailto:contact@noxzi-dev.fr"
                                className="hover:underline"
                            >
                                contact@noxzi-dev.fr
                            </a>
                        </p>
                        <p>
                            Téléphone:{" "}
                            <a href="tel:+33060606060" className="hover:underline">
                                +33 060606060
                            </a>
                        </p>
                    </div>
                    <div className="flex flex-wrap gap-4 mb-6">
                        <button className="bg-[#D0EAC4] text-[#3AA600] font-bold px-5 py-2 rounded-md hover:bg-[#c3e1b7] transition">
                            Obtenir un devis
                        </button>
                        <button className="text-black font-bold px-5 py-2  rounded-md hover:bg-gray-200 transition">
                            En savoir plus
                        </button>
                    </div>
                    <div className="flex flex-wrap gap-4 mt-4">
                        <a
                            href="#"
                            className="hover:scale-110 transition-transform duration-300"
                        >
                            <img src={xLogo} alt="Twitter Logo" className="h-8 md:h-10" />
                        </a>
                        <a
                            href="https://www.instagram.com/hydro.off"
                            className="hover:scale-110 transition-transform duration-300"
                        >
                            <img
                                src={linkedinLogo}
                                alt="Linkedin Logo"
                                className="h-8 md:h-10"
                            />
                        </a>
                        <a
                            href="#"
                            className="hover:scale-110 transition-transform duration-300"
                        >
                            <img src={youtubeLogo} alt="YouTube Logo" className="h-8 md:h-10" />
                        </a>
                        <a
                            href="#"
                            className="hover:scale-110 transition-transform duration-300"
                        >
                            <img
                                src={instagramLogo}
                                alt="Instagram Logo"
                                className="h-8 md:h-10"
                            />
                        </a>
                    </div>
                </div>

                {/* Section droite */}
                <div className="text-left lg:text-right">
                    <ul className="space-y-2">
                        <li>
                            <Link to="/" className="hover:text-green-500 transition">
                                Accueil
                            </Link>
                        </li>
                        <li>
                            <Link to="/services" className="hover:text-green-500 transition">
                                Services
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact" className="hover:text-green-500 transition">
                                Contact
                            </Link>
                        </li>
                        <li>
                            <Link to="/faq" className="hover:text-green-500 transition">
                                F.A.Q
                            </Link>
                        </li>
                        <li>
                            <a
                                href="https://fivem.noxzi-dev.fr"
                                className="hover:text-green-500 transition"
                            >
                                Fivem
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Footer inférieur */}
            <div className="mt-12 text-gray-500 text-sm">
                <hr className="border-gray-300 mb-6" />
                <div className="flex flex-wrap justify-between items-center">
                    <p className="text-center text-xs lg:text-sm">
                        ©2023-2024 NoxZi-Dev - Tous droits réservés
                    </p>
                    <ul className="flex flex-wrap justify-center gap-4 text-xs lg:text-sm mt-4 lg:mt-0">
                        <li>
                            <Link to="/cgu" className="hover:underline">
                                Conditions générales d'utilisation (CGU)
                            </Link>
                        </li>
                        <li>
                            <Link to="/politique-confidentialite" className="hover:underline">
                                Politique Confidentialité
                            </Link>
                        </li>
                        <li>
                            <Link to="/politique-cookies" className="hover:underline">
                                Politique de Cookies
                            </Link>
                        </li>
                        <li>
                            <Link to="/mentions-legales" className="hover:underline">
                                Mentions légales
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}
import React from "react";


const PolitiqueCookies = () => {
  return (
    <div className="">
        <div className="text-black py-10 px-5">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-3xl font-bold mb-5">Politique de Cookies</h1>
                <p className="mb-4">
                    Ce site utilise des cookies uniquement pour assurer le bon fonctionnement du
                    formulaire de contact. Aucune donnée personnelle n'est collectée ou
                    utilisée à des fins publicitaires ou analytiques.
                </p>
                <p className="mb-4">
                    Les informations collectées via le formulaire de contact (nom, prénom, email, numéro de téléphone,
                    message) sont utilisées exclusivement pour répondre à vos demandes.
                </p>
                <p className="mb-4">
                    Vous pouvez configurer votre navigateur pour bloquer les cookies, mais cela peut
                    empêcher le formulaire de contact de fonctionner correctement.
                </p>
            </div>
        </div>
  </div>
  );
};

export default PolitiqueCookies;
